import { storeToRefs } from 'pinia';
import Web3 from 'web3';
import { validate } from 'multicoin-address-validator';

import { useUiStore } from '~/store/ui';
import { useBankingStore } from '@/store/banking';
import { useDepositAccountStore } from '~/store/depositAccount';
import { getConfig } from '@/utils/getConfig';
import { DEPOSIT_ACCOUNT_PROVIDER } from '@/core/deposit-account/provider';

export function useWallet() {
  const bankingStore = useBankingStore();
  const {
    swapsPending,
  } = storeToRefs(bankingStore);
  const {
    withdrawalAvailability,
  } = bankingStore;

  const uiStore = useUiStore();
  const {
    walletModalFundsContext,
  } = storeToRefs(uiStore);

  const depositAccountStore = useDepositAccountStore();
  const {
    add: fetchNewDepositAccount,
  } = depositAccountStore;
  const {
    accounts,
  } = storeToRefs(depositAccountStore);

  const { $api, } = useNuxtApp();

  function setWalletAddressDataUrl(currency, address) {
    switch (currency) {
      case 'BTC':
        return `bitcoin:${address}`;
      case 'WIF':
      case 'SOL':
      case 'BNK1K':
        return `solana:${address}`;
      case 'LTC':
        return `litecoin:${address}`;
      case 'DOGE':
      case 'XRP':
      case 'TRX':
      case 'EOS':
        return address;
      case 'BCH':
        return `bitcoincash:${address}`;
      default:
        return `ethereum:${address}?chainId=${Web3.utils.hexToNumber(getConfig('ETHEREUM_NETWORK_ID'))}`;
    }
  }

  function paymentMethodType(network) {
    switch (network) {
      case 'Arbitrum':
      case 'Optimism':
      case 'Base':
      case 'Binance':
      case 'Polygon':
        return 'Ethereum';
      default:
        return network;
    }
  }

  function validateAddress(inputAddress, network) {
    if (!inputAddress) {
      return false;
    }

    const paymentMethod = paymentMethodType(network);

    switch (paymentMethod) {
      case 'Bitcoin':
      case 'Solana':
      case 'Litecoin':
      case 'Ripple':
      case 'Tron':
      case 'EOS':
      case 'Dogecoin':
      case 'BitcoinCash':
        return validate(inputAddress, paymentMethod.toLowerCase(), getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
      default:
        return Web3.utils.isAddress(inputAddress);
    }
  }

  async function fetchIsWithdrawalAvailable(currency, amount = undefined) {
    try {
      return await withdrawalAvailability(currency, amount, walletModalFundsContext.value);
    } catch (err) {
      return err.message;
    }
  }

  async function fetchPendingWithdrawals(currency) {
    try {
      return await $api('/withdrawal', {
        method: 'GET',
        params: {
          page: 1,
          pageSize: 1,
          pending: true,
          currencyCode: currency,
          fundsContext: walletModalFundsContext.value,
        },
      });
    } catch (err) {
      return err.message;
    }
  }

  // TODO: Consider if this is superceded by fetchPendingWithdrawals();
  function hasLocalPendingWithdrawals(currency) {
    return !!swapsPending.value.find(swap => swap.direction === 'out' && swap.currencyCode === currency);
  }

  function hasLocalPendingDeposits(currency) {
    const pendingDeposit = swapsPending.value.find(swap => swap.direction === 'in' && swap.currencyCode === currency && swap.fundsContext === walletModalFundsContext.value);

    return {
      txHash: pendingDeposit?.txHash || null,
      isLoading: !!pendingDeposit?.txHash,
    };
  }

  async function fetchCryptoCurrencyAddress(currencyCode, network) {
    let account = accounts.value.find(a => a.currencyCode === currencyCode && a.network === network);
    let error;

    if (!account?.address) {
      try {
        account = await fetchNewDepositAccount(currencyCode, DEPOSIT_ACCOUNT_PROVIDER.Fireblocks, network);
      } catch (err) {
        switch (err.message) {
          case 'DepositAccountAlreadyExists':
            error = 'A deposit wallet for the selected currency already exists.';
            break;
          case 'DepositAccountCreationFailed':
            error = 'We have been unable to create your deposit wallet.';
            break;
          default:
            error = 'An unexpected error occurred.';
            break;
        }
      }
    }

    if (account?.address) {
      return {
        address: account.address,
        dataUrl: setWalletAddressDataUrl(currencyCode, account.address),
      };
    } else {
      return {
        error,
      };
    }
  }

  return {
    setWalletAddressDataUrl,
    validateAddress,
    fetchIsWithdrawalAvailable,
    fetchPendingWithdrawals,
    hasLocalPendingWithdrawals,
    hasLocalPendingDeposits,
    fetchCryptoCurrencyAddress,
    paymentMethodType,
  };
}
